import { createRouter, createWebHashHistory } from "vue-router"
// import { useUserSession } from './store/UserSession'
// import { removeToken } from './utils/storage'

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: () => import("@/views/Home")
    }
]

const router = createRouter({
    routes,
    history: createWebHashHistory()
})

export default router